/**
 * Render with React.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Using Styled Components for CSS
 */
import styled, { css } from 'styled-components';

/**
 * Internal links
 */
import { Link } from 'gatsby';

/**
 * Solve Official Logo
 */
import { Button } from '@material-ui/core';
import SolveFinanceLogo from '../images/solveLogoBlackText.svg';

/**
 * Location
 */
import useWindowSize from '../hooks/useWindowSize';
import { NavNode } from './NavNode';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { debtOptimizerUrl } from '../constants';
import { theme } from '../styles/GlobalTheme';
import { useModal } from '../hooks/useModal';
import { trackEvent } from '../analytics';

/**
 * Styled Links
 */
export const NavLink = styled(Link)`
   font-family: 'Majorant-Regular', Helvetica;
   font-size: 1rem;
   white-space: nowrap;
   text-decoration: none;
   margin: auto;
   margin-right: 2rem;

   &:hover > span {
    border-bottom: 3px solid #0286e7;
    margin-bottom: -3px;
   }

   ${({ to, currentPage }) =>
     (currentPage || '').startsWith(to) &&
     css`
       &&& {
         text-decoration: underline;
       }
     `}
 }`;

const Nav = styled('nav')`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

/**
 * Links to render
 */
const leftHeaderLinks = [
  [
    'link',
    '/',
    undefined,
    undefined,
    <img
      style={{ width: '113px' }}
      key={'logo'}
      src={SolveFinanceLogo}
      alt="Solve Finance"
    />,
  ],
  [
    'nested',
    undefined,
    'Solutions',
    'By User Type',
    [
      {
        to: '/financial-advisor-for-your-users/',
        title: 'Partners',
        subtext: 'Enable easier borrowing for your users',
      },
      {
        to: '/financial-advisor-for-individuals/',
        title: 'Individuals',
        subtext: 'Get started with our Debt Optimizer',
      },
    ],
  ],
  [
    'nested',
    undefined,
    'Tools',
    undefined,
    [
      {
        to: debtOptimizerUrl + '?utm_source=www',
        title: 'Debt Optimizer',
        subtext: 'Put better borrowing on automatic with our full service app',
      },
      {
        to: '/mortgage-dti-calculator/',
        title: 'DTI Calculator',
        subtext: 'Free: quickly calculate your debt-to-income ratio',
      },
      {
        to: '/how-much-house-can-i-afford-calculator/',
        title: 'Home Affordability Maximizer',
        subtext: 'Free: estimate your home-buying power',
      },
      {
        to: '/how-to-borrow-online/',
        title: 'Borrow Online',
        subtext: 'Free: how to borrow online quickly',
      },
      {
        to: '/independent-mortgage-advice/',
        title: 'Home Finance',
        subtext: 'Free: independent mortgage calculators',
      },
      {
        to: '/how-to-borrow-against-your-house/',
        title: 'Borrow From Your House',
        subtext: 'Free: how do you get equity out of your home?',
      },
    ],
  ],
  ['link', '/pricing/', undefined, undefined, 'Pricing'],
  [
    'nested',
    undefined,
    'Company',
    undefined,
    [
      {
        to: '/about-solve-finance',
        title: 'About',
      },
      {
        to: 'https://apply.workable.com/solve-finance/?lng=en',
        title: 'Careers',
      },
    ],
  ],
  ['link', '/resources/', 'Resources', undefined, 'Resources'],
];

const ctaStyle = {
  borderRadius: 8,
  fontSize: 14,
  height: '32px',
  minHeight: '32px',
  minWidth: '110px',
  color: theme.white,
  backgroundColor: theme.blue4,
  border: `2px solid ${theme.blue4}`,
  padding: '4px 16px',
  marginRight: '12px',
  textTransform: 'none',
  whiteSpace: 'noa  wrap',
};

/**
 * The header navigation bar.
 */
const Header = ({ className, currentPage }) => {
  const windowSize = useWindowSize();
  const { y: scrollY } = useScrollPosition();

  const isPartnerPage = [
    'financial-advisor-for-your-users',
    'financial-advisor-for-your-users',
    'financial-advice-to-help-employees-with-debt',
  ].includes(currentPage.replaceAll('/', ''));

  const [, setModalState] = useModal();
  const onClick = () => {
    setModalState({ showing: true, type: 'demo' });
    trackEvent('Request Demo', { type: 'LENDER' });
  };

  return (
    <Nav className={className} scrolled={scrollY > 0}>
      <div id="header-content" role="menubar">
        <div>
          {leftHeaderLinks.map(([type, to, title, subtext, children]) =>
            type === 'nested' ? (
              <NavNode title={title} subtext={subtext} key={to}>
                {children}
              </NavNode>
            ) : (
              <NavLink to={to} key={to} role="menuitem">
                <span>{children}</span>
              </NavLink>
            )
          )}
        </div>

        <div>
          {currentPage !== '/new-employer' && !isPartnerPage && (
            <>
              <Link href={debtOptimizerUrl + '/sign-in?utm_source=www'}>
                Log In
              </Link>
              <Button
                style={ctaStyle}
                href={debtOptimizerUrl + '/onboarding?utm_source=www'}
              >
                Get started
              </Button>
            </>
          )}
          {isPartnerPage && (
            <Button style={ctaStyle} to="javascript:" onClick={onClick}>
              Request Demo
            </Button>
          )}
        </div>
      </div>
    </Nav>
  );
};

/**
 * Typechecking
 */
Header.propTypes = {
  mobile: PropTypes.bool,
  currentPage: PropTypes.string,
};

/**
 * Defaults
 */
Header.defaultProps = {
  mobile: false,
  callToAction: 'Get Started',
  currentPage: '/',
};

/**
 * The Final Exported Container
 */
const StyledHeader = styled(Header)`
  display: none;
  @media ${theme.mq.desktopLargeMin} {
    position: fixed;
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 2;
    height: 68px;

    &.scrolled {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    #header-content {
      display: flex;
      margin: 8px auto 0 auto;
      max-width: 1300px;
      height: 56px;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      z-index: 3;
      & > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
      }

      & > div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export default StyledHeader;
